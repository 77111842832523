* {
  padding: 0;
  margin: 0;
}

body {
  font-family: "Raleway", sans-serif;
}

a {
  color: black;
  text-decoration: none;
}

:root {
  --lowTransition: 0.3s;
}

/* Start Responsive Containers */

.container {
  margin-left: auto;
  margin-right: auto;
  padding: 0 10px;
}

/* Small Screens */
@media (min-width: 767px) {
  .container {
    width: 750px;
  }
}

/* Medium Screens */
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

/* Large Screens */
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

/* End Responsive Containers */

/* Start Body Tag  */

@media (max-width: 767px) {
  .responsive {
    width: 160vw;
  }
}

/* End Body Tag  */

/* Start Nav.jsx */

nav {
  width: 100%;
}

.logo {
  width: 275px;
  margin: auto;
}

@media (max-width: 767px) {
  .logo {
    width: 150px;
  }
}

.logoImage {
  width: 100%;
}

/* End Nav.jsx */
/* Start Data.jsx */

.dataContainer {
  margin: 50px auto;
  padding: 20px;
  width: 100%;
  border-radius: 10px;
  box-shadow: 1px 1px 5px 0.2px gray;
  transition: var(--lowTransition);
}

@media (max-width: 500px) {
  .dataContainer {
    width: 80vw;
  }
}

.dataContainer:hover {
  box-shadow: 1px 1px 10px 2px gray;
}

.title {
  text-align: center;
  font-size: 45px;
  color: #38b6ff;
  margin-bottom: 25px;
}

.form {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.form button {
  width: 100%;
  color: white;
  font-size: 20px;
  background-color: rgba(57, 182, 255, 0.8);
  outline: 0;
  border: 0;
  border-radius: 9999px;
  padding: 10px;
  cursor: pointer;
  transition: var(--lowTransition);
}

.form button:hover {
  background-color: rgb(40, 175, 252);
}

input[type="text"],
input[type="number"] {
  outline: 0;
  border: 0;
  border-bottom: 1px solid gray;
  transition: var(--lowTransition);
  padding: 10px 5px 10px 5px;
  font-size: 18px;
}

input[type="text"]:focus,
input[type="number"]:focus {
  border-radius: 10px;
  background-color: rgb(224, 224, 224);
}

input[type="color"] {
  border: 0;
  height: 40px;
}

select {
  width: 100%;
}

.full,
.half {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
}

.full {
  width: 100%;
}

.full input,
select {
  width: 85%;
}

.full h3 {
  width: 15%;
}

.half {
  width: calc(50% - 10px);
}

.half input,
select {
  width: 70%;
}

.half h3 {
  width: 30%;
}

.datasets {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.dataset {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.dataset .half {
  width: 45%;
  justify-content: center;
}

.datasetInput {
  width: 100%;
}

@media (max-width: 500px) {
  .dataset .half {
    width: 50%;
    justify-content: center;
  }

  .dataset .half input {
    width: calc(100% - 25px);
  }
}

@media (max-width: 767px) {
  .half {
    width: 100%;
  }

  .half input,
  select {
    width: 85%;
  }

  .half h3 {
    width: 15%;
  }
}

@media (max-width: 500px) {
  .full input,
  select {
    width: 50%;
  }

  .full h3 {
    width: 50%;
  }

  .half input,
  select {
    width: 50%;
  }

  .half h3 {
    width: 50%;
  }
}

.breaker {
  margin: auto;
  width: 76%;
  background-color: black;
  border: 2px solid black;
}

.form h2,
.form h4 {
  width: 100%;
  text-align: center;
}

.colorState {
  width: 50%;
}

.colorState input {
  width: 10%;
}

.datasetManipulation {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.datasetManipulation svg {
  margin: 1px;
  padding: 4px;
  cursor: pointer;
}

#reset {
  width: fit-content;
  margin: auto;
  background-color: #ff0000aa;
  transition: var(--lowTransition);
}

#reset:hover {
  background-color: #ff0000;
}

/* End Data.jsx */
/* Start Chart.jsx */

.chart {
  margin: 50px 0 0 0;
  position: relative;
  width: 100%;
}

.buttons {
  margin: 50px 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  border: 3px solid #38b6ff;
  padding: 10px 0;
  border-radius: 9999px;
}

.buttons button {
  color: white;
  font-size: 20px;
  background-color: rgba(57, 182, 255, 0.8);
  outline: 0;
  border: 0;
  border-radius: 9999px;
  padding: 10px;
  cursor: pointer;
  transition: var(--lowTransition);
}

@media (max-width: 767px) {
  .buttons button {
    font-size: 15px;
    padding: 8px;
  }
}

.buttons button:hover {
  background-color: rgb(40, 175, 252);
}

/* End Chart.jsx */
